import { graphql } from "gatsby"
import * as React from "react"
import { useState, useEffect } from "react"
import isSameYear from "date-fns/isSameYear"
import Layout from "../components/layout/Layout/Layout"
import PostPreview from "../components/posts/PostPreview/PostPreview"
import SEO from "../components/SEO/SEO"
import range from "../util/range"

const IndexPage: React.FC<{ data: Queries.AllPostPreviewQuery }> = ({ data }) => {
  const [postsByYear, setPostsByYear] = useState<{ [key: number]: typeof data.allSanityPost.edges }>({})

  useEffect(() => {
    if (data.allSanityPost.edges) {
      const sortedPosts: { [key: number]: typeof data.allSanityPost.edges } = {}
      const currentYear = new Date().getUTCFullYear()

      const yearRange = range(2017, currentYear, 1)

      while (yearRange.length > 0) {
        const yearToCheck: number | undefined = yearRange.pop()

        if (yearToCheck) {
          sortedPosts[yearToCheck] = []

          data.allSanityPost.edges.forEach((pst) => {
            if (pst.node.publishedAt) {
              const rightDate = new Date(pst.node.publishedAt)
              const leftDate = new Date(yearToCheck, 1, 1)

              const sameYear = isSameYear(leftDate, rightDate)

              if (sameYear && Array.isArray(sortedPosts[yearToCheck])) {
                sortedPosts[yearToCheck] = [...sortedPosts[yearToCheck], pst]
              }
            }
          })
        }
      }

      setPostsByYear(sortedPosts)
    }
  }, [])

  return (
    <Layout>
      <SEO
        title="Home"
        description="A chronological look at all my posts over the years with the latest posts at the top."
        image="https://cdn.sanity.io/images/zlybple6/production/ada257f4bf0fb34f744576d8517e8b381d5bb9ee-1280x516.jpg?w=1280&h=516&auto=format"
        article={false}
      />
      <div
        style={{
          maxWidth: `1000px`,
          margin: `auto`,
          padding: `56px 32px`,
          background: `radial-gradient(circle, rgba(235,235,235,1) 0%, rgba(255,255,255,1) 100%)`,
        }}
      >
        <h1>All Posts</h1>
        {Object.keys(postsByYear)
          .sort((a: string, b: string) => Number(b) - Number(a))
          .map((yr) => {
            const postsToRender = postsByYear[Number(yr)]

            return (
              <div
                style={{
                  marginBottom: `56px`,
                  display: `flex`,
                  flexDirection: `column`,
                }}
              >
                <h2>{yr}</h2>
                {postsToRender.map(({ node }) => {
                  return <PostPreview node={node} />
                })}
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query AllPostPreview {
    allSanityPost(
      sort: { order: DESC, fields: publishedAt }
      filter: { categories: { elemMatch: { title: { in: ["nature", "progress"] } } } }
    ) {
      edges {
        node {
          ...PostPreviewDetails
        }
      }
    }
  }
`
